import React, { FC } from 'react';

import { SoBannerItemProps } from '../../models';

const TextVariant: FC<SoBannerItemProps> = ({
  data: { imageDesktop, imageMobile, extraClass, button },
  index,
}) => (
  <div
    className={`banner__item banner__item--text-variant ${extraClass || ''}`}
    key={`sobannerItemImage_${index}`}
  >
    {button ? (
      <a href={button.url}>
        <div className="d-none d-md-block">
          {imageDesktop ? (
            <img
              src={imageDesktop.fallbackUrl}
              alt={imageDesktop.altText ? imageDesktop.altText : ''}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="d-block d-md-none">
          {imageMobile ? (
            <img
              src={imageMobile.fallbackUrl}
              alt={imageMobile.altText ? imageMobile.altText : ''}
            />
          ) : (
            <></>
          )}
        </div>
      </a>
    ) : (
      <>
        <div className="d-none d-md-block">
          <img
            src={imageDesktop.fallbackUrl}
            alt={imageDesktop.altText ? imageDesktop.altText : ''}
          />
        </div>
        <div className="d-block d-md-none">
          <img src={imageMobile.fallbackUrl} alt={imageMobile.altText ? imageMobile.altText : ''} />
        </div>
      </>
    )}
  </div>
);

export default TextVariant;
