import React, { FC } from 'react';
import Carousel from 'nuka-carousel';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { arrayChunk } from '../../../../utils/functions';
import { SoBannerItem, SobannerProps } from '../../models';
import { SliderPage } from './snipets';

const SliderContainerVariant: FC<SobannerProps> = ({
  data: { title, extraClass, items, infiniteLoop, containerImage, containerText, pageSize },
}) => {
  const itemsP: unknown = items;
  const pages = arrayChunk(itemsP as never[], pageSize || 1);

  return (
    <div className={`soBanner soBanner__container soBanner__container--slider ${extraClass || ''}`}>
      {title ? <h2 className="soBanner__title">{title}</h2> : <></>}
      <div className="soBanner__info row align-items-center">
        <div className="soBanner__image col-md-6">
          {containerImage ? (
            <img
              src={containerImage.fallbackUrl}
              alt={containerImage.altText ? containerImage.altText : ''}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="soBanner__text col-md-6">
          {containerText ? <DangerouslySetInnerHtml html={containerText as string} /> : <></>}
        </div>
      </div>

      <div className="soBanner__carousel">
        <Carousel
          autoplay
          autoplayInterval={5000}
          wrapAround={infiniteLoop === 'yes'}
          withoutControls={false}
        >
          {pages.map((page, index: number) => {
            const pageP: unknown = page;

            return <SliderPage items={pageP as [SoBannerItem]} indexPage={index} />;
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default SliderContainerVariant;
