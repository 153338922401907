export const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const arrayChunk = (items = [], n: number = 3) => {
  const chunks: Array<typeof items> = [];

  const array = items.slice();
  while (array.length) {
    const chunk = array.splice(0, n);
    chunks.push(chunk);
  }

  return chunks;
};

export default isEmpty;
