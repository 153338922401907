import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import SoBanner from 'components/SoBanner';

import { ContentPageProps } from './models';

import './ContentPage.scss';

const ContentPage: FC<ContentPageProps> = ({
  data: {
    contentPage: { content, seoMetaTitle, seoMetaDescription, seoMetaKeywords },
  },
}) => (
  <Layout className="contentPage">
    {seoMetaTitle !== undefined &&
      seoMetaDescription !== undefined &&
      seoMetaKeywords !== undefined && (
        <Seo
          {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
        />
      )}
    {seoMetaTitle !== undefined &&
      seoMetaDescription !== undefined &&
      seoMetaKeywords !== undefined && (
        <PageSchema
          type="WebPage"
          name={seoMetaTitle}
          data={{
            metaTitle: seoMetaTitle,
            metaDescription: seoMetaDescription,
            metaKeywords: seoMetaKeywords,
          }}
        />
      )}
    {content?.map((data, index) => (
      <SoBanner data={data} index={index} />
    ))}
  </Layout>
);

export const query = graphql`
  query ($url: String) {
    contentPage(url: { eq: $url }) {
      seoMetaTitle
      seoMetaDescription
      url
      content {
        title
        subtitle
        variant
        containerText
        imageButtonPopup {
          url
          altText
        }
        legalText
        pageSize
        containerImage {
          altText
          fallbackUrl
        }
        extraClass
        showArrows
        infiniteLoop
        items {
          variant
          extraClass
          legalText
          bannerText
          buttonType
          button {
            url
            icon
            name
            published
            udi
            trashed
          }
          videoBanner {
            altText
            fallbackUrl
            url
          }
          videoBannerMobile {
            altText
            fallbackUrl
            url
          }
          imageDesktop {
            altText
            fallbackUrl
          }
          imageMobile {
            altText
            fallbackUrl
          }
          imageProduct {
            altText
            fallbackUrl
          }
          imageRecipe {
            altText
            fallbackUrl
          }
          articleImage {
            altText
            fallbackUrl
            url
          }
        }
      }
    }
  }
`;

export default ContentPage;
