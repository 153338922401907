import React, { FC } from 'react';
import Carousel from 'nuka-carousel';

import { SoBannerItem, SobannerProps } from '../../models';
import {
  ImageVariant,
  ProductVariant,
  RecipeVariant,
  TextRightVariant,
  TextVariant,
} from '../items';

const DefaultContainerVariant: FC<SobannerProps> = ({
  data: { title, extraClass, items, infiniteLoop },
}) => (
  <div className={`soBanner soBanner__container soBanner__container--default ${extraClass || ''}`}>
    {title ? <h2 className="soBanner__title">{title}</h2> : <></>}

    <Carousel
      autoplay
      autoplayInterval={5000}
      wrapAround={infiniteLoop === 'yes'}
      withoutControls={false}
    >
      {items.map((item: SoBannerItem, index: number) => {
        const { variant } = item;
        switch (variant[0]) {
          case 'text':
            return <TextVariant data={item} index={index} />;
          case 'textRight':
            return <TextRightVariant data={item} index={index} />;
          case 'product':
            return <ProductVariant data={item} index={index} />;
          case 'recipe':
            return <RecipeVariant data={item} index={index} />;
          case 'image':
            return <ImageVariant data={item} index={index} />;
          default:
            return <>{variant}</>;
        }
      })}
    </Carousel>
  </div>
);

export default DefaultContainerVariant;
