import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { SoBannerItemProps } from '../../models';

const ArticleVariant: FC<SoBannerItemProps> = ({
  data: { articleImage, extraClass, button, bannerText, title, legalText, buttonType },
  index,
}) => (
  <div
    className={`banner__item banner__item--text-variant ${extraClass || ''}`}
    key={`sobannerItemImage_${index}`}
  >
    <div className="article__content">
      {button[0].url ? (
        <a href={button[0].url} className="article__link">
          {articleImage ? (
            <img
              src={articleImage.fallbackUrl}
              alt={articleImage.altText ? articleImage.altText : ''}
              className="article__image"
            />
          ) : (
            <></>
          )}
        </a>
      ) : (
        <>
          {articleImage ? (
            <img
              src={articleImage.fallbackUrl}
              alt={articleImage.altText ? articleImage.altText : ''}
              className="article__image"
            />
          ) : (
            <></>
          )}
        </>
      )}
      {title ? (
        <DangerouslySetInnerHtml className="article__title" html={title as string} />
      ) : (
        <></>
      )}
      <div className="article__text">
        {bannerText ? <DangerouslySetInnerHtml html={bannerText as string} /> : <></>}
        {button[0].url ? (
          <a href={button[0].url} className={`btn ${buttonType ? `btn--${buttonType}` : ''}`}>
            {button[0].name}
          </a>
        ) : (
          <></>
        )}
      </div>
      {legalText ? (
        <div className="article__legal-text">
          <DangerouslySetInnerHtml html={legalText as string} />
        </div>
      ) : (
        <></>
      )}
    </div>
  </div>
);

export default ArticleVariant;
