import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import ScrollPopupEffect from 'components/ScrollPopupEffect';

import { SoBannerItemProps } from '../../models';

const ProductVariant: FC<SoBannerItemProps> = ({
  data: { extraClass, legalText, bannerText, button, buttonType, imageProduct },
  index,
}) => (
  <div className={`card card__item--${extraClass || ''}`} key={`sobannerItemProduct_${index}`}>
    {imageProduct && imageProduct.fallbackUrl ? (
      <div className="card__image">
        <img
          src={imageProduct.fallbackUrl}
          alt={imageProduct.altText ? imageProduct.altText : ''}
        />
      </div>
    ) : (
      <></>
    )}
    <ScrollPopupEffect>
      <div className="card__body">
        {bannerText ? (
          <DangerouslySetInnerHtml className="card__text" html={bannerText as string} />
        ) : (
          <></>
        )}
        {legalText ? (
          <DangerouslySetInnerHtml className="card__legal-text" html={legalText as string} />
        ) : (
          <></>
        )}
      </div>
    </ScrollPopupEffect>

    {button ? (
      <div className="card__footer">
        <a href={button.url} className={`btn ${buttonType ? `btn--${buttonType}` : ''}`}>
          {button.name}
        </a>
      </div>
    ) : (
      <></>
    )}
  </div>
);

export default ProductVariant;
