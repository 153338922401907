import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { SoBannerItemProps } from '../../models';

const TextRightVariant: FC<SoBannerItemProps> = ({
  data: { imageDesktop, imageMobile, extraClass, legalText, bannerText, button, buttonType },
  index,
}) => (
  <div
    className={`banner__item banner__item--text-right-variant banner__item--${extraClass || ''}`}
    key={`sobannerItemText_${index}`}
  >
    <div className="d-none d-md-block">
      {imageDesktop ? (
        <img
          src={imageDesktop.fallbackUrl}
          alt={imageDesktop.altText ? imageDesktop.altText : ''}
        />
      ) : (
        <></>
      )}
    </div>
    <div className="d-block d-md-none">
      {imageMobile ? (
        <img src={imageMobile.fallbackUrl} alt={imageMobile.altText ? imageMobile.altText : ''} />
      ) : (
        <></>
      )}
    </div>
    <div className="banner__info">
      <div className="banner__info-content">
        {bannerText ? <DangerouslySetInnerHtml html={bannerText as string} /> : <></>}
        {button ? (
          <a href={button.url} className={`btn ${buttonType ? `btn--${buttonType}` : ''}`}>
            {button.name}
          </a>
        ) : (
          <></>
        )}
        {legalText ? (
          <div className="legal-text">
            <DangerouslySetInnerHtml html={legalText as string} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  </div>
);

export default TextRightVariant;
